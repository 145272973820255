/* @font-face {
  font-family: "Poetsen One";
  src: local("Poetsen One"), url(./fonts/Poetsen One.woff) format("woff");
} */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #585f48;
  box-sizing: border-box;
}

*,
:after,
:before {
  box-sizing: border-box;
}

a {
  color: white;
  text-decoration: none;
  border-bottom: 2px solid white;
}

*:focus {
  outline: none;
}
